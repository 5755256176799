import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '../shared/services/devices.service';
import { IntervalDataTypes } from './../shared/model/device';
import { OrganizationsService } from '../shared/services/organizations.service';
import { SiteService } from '../shared/services/sites.service';
import { CRM_ID, INTEGER_ONLY, MAC_ADDRESS, UUID } from '../shared/validators/inputs.validators';
import { MixPanelService } from '../shared/services/mixpanel.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss', '../shared/shared.styles.scss'],
})
export class CreateComponent {
  SUCCESS = 'Created Successfully';
  BAD_REQUEST = 'Oops, There was a problem with your request';
  NOT_CREATED = 'Oops, There was a problem creating your device';
  REQUIRED = 'required';

  isSubmitting = false;

  deviceForm: FormGroup = new FormGroup({
    autoThingId: new FormControl(''),
    searchDeviceId: new FormControl(''),
    autoSiteId: new FormControl(''),
    autoPulseMultiplier: new FormControl(null),
    displayLabels: new FormControl('', Validators.required),
    status: new FormControl('', Validators.required),
    siteId: new FormControl('', Validators.required),
    equipmentIds: new FormControl([], Validators.required),
    supportedLocales: new FormControl([]),
    defaultLocale: new FormControl('en_US'),
    macAddress: new FormControl(null, Validators.pattern(MAC_ADDRESS)),
    installerContactId: new FormControl(null, Validators.pattern(UUID)),
    gatewayType: new FormControl(null),
    gatewayId: new FormControl(null),
    crmId: new FormControl(null, Validators.pattern(CRM_ID)),
    host: new FormControl(null, Validators.maxLength(255)),
    port: new FormControl(null, [Validators.min(1), Validators.pattern(INTEGER_ONLY)]),
    includeInCalcs: new FormControl(null),
    emulated: new FormControl(null),
    signalStrength: new FormControl(null),
    signalQuality: new FormControl(null),
    notes: new FormControl(null, Validators.maxLength(2048)),
  });
  readonly APPPREFIX = 'prt';
  readonly mode = 'create';

  constructor(
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private router: Router,
    private messageService: GlobalAlertService,
    private deviceService: DeviceService,
    private translateService: TranslateService,
    private organizationsService: OrganizationsService,
    private siteService: SiteService,
    private mixpanelService: MixPanelService,
  ) {
    this.deviceService.selectedDeviceId$.next('');
    this.translateService.get('device.notification.created_successfully').subscribe((result: string) => {
      this.SUCCESS = result;
      this.BAD_REQUEST = this.translateService.instant('device.notification.bad_request');
      this.NOT_CREATED = this.translateService.instant('device.notification.not_created');
      this.REQUIRED = this.translateService.instant('device.validation.required');
    });
    if (this.organizationsService.isInit) {
      this.organizationsService.getOrgs();
    }
  }

  handleCancel() {
    this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX });
    this.router.navigate(['/']);
  }

  async handleSubmit() {
    if (!this.deviceForm.valid) {
      this.messageService.setError(this.REQUIRED);
    } else {
      this.mixpanelService.saveCreateDevice();
      try {
        this.isSubmitting = true;
        const device = { ...this.deviceForm.getRawValue() };
        if (device?.intervalDataTypes) {
          device.IntervalDataTypes = new IntervalDataTypes(device.intervalDataTypes);
        }
        for (let property in device) {
          if (device[property] == null || device[property] == '') {
            delete device[property];
          }
        }
        delete device.channels;
        delete device.supportedLocales;
        const response = await this.deviceService.createDevice(device);
        this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX, callbackValue: response.id });
        this.messageService.setSuccess(this.SUCCESS);
        this.deviceService.refetchDevices();
        this.siteService.getSites();
        this.router.navigate([`details/${response.id}/view`], {});
      } catch (e) {
        console.log(e);
        if (e) {
          const errorMessage = e.error.message;
          if (errorMessage === 'ERR_BAD_REQUEST') {
            this.messageService.setError(this.BAD_REQUEST);
          } else {
            this.messageService.setError(this.NOT_CREATED);
          }
        }
      }
      this.isSubmitting = false;
    }
  }
}
