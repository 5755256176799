import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { DeviceService } from '../shared/services/devices.service';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../dialogs/delete/delete-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Device, IntervalDataTypes } from '../shared/model/device';
import { Locale } from '../shared/model/locale.model';
import { OrganizationsService } from '../shared/services/organizations.service';
import { SiteService } from '../shared/services/sites.service';
import { CRM_ID, INTEGER_ONLY, MAC_ADDRESS, UUID } from '../shared/validators/inputs.validators';
import { ContextSelectorService } from 'ngx-global-nav';
import { MixPanelService } from '../shared/services/mixpanel.service';
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss', '../shared/shared.styles.scss'],
})
export class EditComponent implements OnInit, OnDestroy {
  SUCCESS = 'Created Successfully';
  BAD_REQUEST = 'Oops, There was a problem with your request';
  ORPHANED_ERROR =
    'The site or organization this device is associated with no longer exists. Please update the site and status and try again.';
  NOT_CREATED = 'Oops, There was a problem creating your asset';
  REQUIRED = 'required';
  CONFLICT = 'There is a data conflict with this Device';

  deviceId = '';
  subscriptions = [];
  isSubmitting = false;
  isLoadingAsset = false;

  currentOrg = '';

  currentSite = null;
  deviceForm: FormGroup = new FormGroup({
    displayLabels: new FormControl('', Validators.required),
    status: new FormControl('', Validators.required),
    siteId: new FormControl('', Validators.required),
    equipmentIds: new FormControl([], Validators.required),
    supportedLocales: new FormControl([new Locale()]),
    defaultLocale: new FormControl('en_US'),
    macAddress: new FormControl(null, Validators.pattern(MAC_ADDRESS)),
    installerContactId: new FormControl(null, Validators.pattern(UUID)),
    gatewayType: new FormControl(null),
    channels: new FormControl(null),
    gatewayId: new FormControl(null),
    crmId: new FormControl(null, Validators.pattern(CRM_ID)),
    host: new FormControl(null, Validators.maxLength(255)),
    port: new FormControl(null, [Validators.min(1), Validators.pattern(INTEGER_ONLY)]),
    includeInCalcs: new FormControl(null),
    emulated: new FormControl(null),
    signalStrength: new FormControl(null),
    signalQuality: new FormControl(null),
    notes: new FormControl(null, Validators.maxLength(2048)),
  });
  readonly mode = 'edit';
  readonly APPPREFIX = 'prt';
  device: Device;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private messageService: GlobalAlertService,
    private deviceService: DeviceService,
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private translateService: TranslateService,
    private organizationsService: OrganizationsService,
    public dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private siteService: SiteService,
    private contextSelectorService: ContextSelectorService,
    private mixpanelService: MixPanelService,
  ) {
    this.translateService.get('device.notification.updated_successfully').subscribe((result: string) => {
      this.SUCCESS = result;
      this.BAD_REQUEST = this.translateService.instant('device.notification.bad_request');
      this.ORPHANED_ERROR = this.translateService.instant('device.notification.orphaned_error');
      this.NOT_CREATED = this.translateService.instant('device.notification.not_created');
      this.REQUIRED = this.translateService.instant('device.validation.required');
      this.CONFLICT = this.translateService.instant('device.notification.conflict');
    });

    const errorSub = this.deviceService.conflictError.subscribe(() => {
      this.messageService.setError(this.CONFLICT, 7000);
    });

    this.subscriptions.push(errorSub);
  }

  ngOnInit() {
    const contextSub = this.contextSelectorService.currentContext$.subscribe((org) => {
      if (!this.currentOrg && org[0].id !== '') {
        this.currentOrg = org[0].id;
      } else if (this.currentOrg !== org[0].id) {
        this.deviceService.devices$.next([]);
        this.router.navigate([`/`], {});
      }
    });
    const routeSub = this.route.params.subscribe(async (params) => {
      if (params.id) {
        this.deviceId = params.id;
        this.isLoadingAsset = true;
        this.deviceService.selectedDeviceId$.next(this.deviceId);
        this.deviceService.selectDevice(params.id);
        this.isLoadingAsset = false;
      }
    });

    const deviceSub = this.deviceService.device$.subscribe(async (device) => {
      if (device) {
        this.device = device;
        this.deviceForm.patchValue(device);
        this.organizationsService.getOrgs(device.site.organizationId);
      }
    });

    this.subscriptions = [...this.subscriptions, routeSub, deviceSub, contextSub];
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  async handleSubmit() {
    if (!this.deviceForm.valid) {
      this.messageService.setError(this.REQUIRED);
    } else {
      this.mixpanelService.saveEditDevice();
      try {
        this.isSubmitting = true;
        const device = { ...this.deviceForm.getRawValue() };
        if (device?.intervalDataTypes) {
          device.IntervalDataTypes = new IntervalDataTypes(device.intervalDataTypes);
        }
        for (let property in device) {
          if (device[property] == null || device[property] == '') {
            delete device[property];
          }
        }
        delete device.channels;
        delete device.supportedLocales;
        device.id = this.deviceId;
        const response = await this.deviceService.updateDevice(device);
        this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX, callbackValue: response.id });
        this.messageService.setSuccess(this.SUCCESS);
        this.deviceService.refetchDevices();
        this.siteService.getSites();
        this.router.navigate([`details/${this.deviceId}/view`], {});
      } catch (e) {
        console.log(e);
        let errorMessage = '';
        if (e.error && e.error.detail) {
          if (e.error.detail === 'body: data.status should be equal to one of the allowed values') {
            this.messageService.setError(this.ORPHANED_ERROR);
          } else {
            this.messageService.setError(e.error.detail);
          }
        } else {
          if (e.error && e.error.message) {
            errorMessage = e.error.message;
          } else {
            errorMessage = 'ERR_BAD_REQUEST';
          }
          if (errorMessage === 'ERR_BAD_REQUEST') {
            this.messageService.setError(this.BAD_REQUEST);
          } else {
            this.messageService.setError(this.NOT_CREATED);
          }
        }
      }
      this.isSubmitting = false;
    }
  }

  openDeleteDialog(): void {
    this.mixpanelService.deleteDevice();
    this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: {
        deviceId: this.deviceId,
      },
    });
  }

  handleCancel() {
    this.router.navigate([`details/${this.deviceId}/view`]);
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
}
